import React,{useState} from 'react'
import Layout from "../../Components/reusable/Layout"
import Banner from "../../Components/reusable/Banner"
import img from "../../Images/products/agri.jpg"
import { ToastContainer, toast } from 'react-toastify';

import store from "store"

function CategoryDetail({title,AddToEnq,short_desc,full_desc,img,children}) {

    
    const [value, setvalue] = useState(100)
    const [productname,setProductName] = useState(title)
    const [message,setMessage] = useState("")
    
    const [products, setproducts] = useState([])
    
     
    const inc = ()=>{
        setvalue(value+100)
    }
    const dec = ()=>{
        if (value>100) {
            setvalue(value-100)
        }
    }

    const handleChange =(e)=>{
       
            setvalue(Number(e.target.value))
        
    }
    const handleMessage = (e)=>{
        setMessage(e.target.value)
    }

    return (
       <Layout items={""}>
           
            <Banner title={title} />
            <ToastContainer />

           <section className="my-5 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                           {
                               img? <img src={`https://www.iamrachit.in/klsons/uploads/${img}`} alt="agri" />:<div className="spinner-border" role="status">
  <span className="sr-only">Loading...</span>
</div>
                           }
                        </div>
                        <div className="col-md-6">
                           
                            <h2>{title}</h2>

                            {
                                  short_desc?<div dangerouslySetInnerHTML={{__html: short_desc}} />:<div className="spinner-border" role="status">
  <span className="sr-only">Loading...</span>
</div>
                            }
                           


                            {/* <h6 className="mt-5">Quantity</h6>
                            <div className="mt-3 mb-5">
                                <button onClick={()=>dec()} className="btn mx-1" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px"}} aria-labelledby="decrement" ><i className="fas fa-minus-circle"></i></button>
                                <input  onChange={handleChange} value={1}  className="form-group" type="number" id="value"  min={"1"} style={{textAlign:"center"}}  />
                                <button onClick={()=>inc()} className="btn mx-1" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px"}} aria-labelledby="increment"><i className="fas fa-plus-circle"></i></button>
                            </div> */}
                        
                           

                            {/* <!-- Button trigger modal --> */}
<button type="button" style={{display:"block"}} className="btn btn-dark mt-5" data-toggle="modal" data-target="#exampleModalCenter">
Enquire Now
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle" style={{fontSize:"20px", margin:"0px"}}>{title}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

       <div className="modal-body">
           
           <label className="mt-2">How many units you need?</label><br/>
            <div className="my-3 quantity form-group" style={{position:"relative"}}>
                
                <input  onChange={handleChange} value={value}  className="form-control" type="number" id="value"  min={"1"} style={{textAlign:"center"}}  />
                <button onClick={dec} className="btn mx-1 dec" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px",position:"absolute"}} aria-labelledby="decrement" >-</button>
                <button onClick={inc} className="btn mx-1 inc" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px",position:"absolute"}} aria-labelledby="increment">+</button>
                
            </div>
              <br/>
            <label>Additional Message</label>
            <textarea type="text" rows="3" className="form-control" style={{width:"100%"}} onChange={handleMessage} value={message}></textarea>
            {/* <h3>Quantity:{1}</h3> */}
      </div>
      <div className="modal-footer">
        <button onClick={()=>AddToEnq(value,message)} type="button" className="btn btn-primary" data-dismiss="modal">Add to Enquiry</button>
      </div>
    </div>
  </div>
</div>
                        </div>
                    </div>
               
<div className="row my-5">
    <div className="col-md-12">
        {
            full_desc?<div dangerouslySetInnerHTML={{__html: full_desc}} />:<div className="spinner-border" role="status">
  <span className="sr-only">Loading...</span>
</div>
        }
    </div>
</div>

                   
               
                </div>
                
            {
             children
           }
           </section>

          
       </Layout>
   
    
    )
}

export default CategoryDetail
